var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1 d-flex"},[_vm._v("Ryhmät")]),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_c('v-spacer'),(_vm.isAuthorized('group', 'create'))?_c('v-btn',{staticClass:"mr-1 mb-2 ml-auto",attrs:{"color":"info"},on:{"click":function($event){_vm.editing = false;
          _vm.groupDialog = true;}}},[_vm._v(" Uusi ryhmä ")]):_vm._e()],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nimi","single-line":"","hide-details":"","append-icon":"mdi-magnify","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headersByRole,"items":_vm.groups,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))])]}},{key:"item.users",fn:function(ref){
          var item = ref.item;
return _vm._l((item.users),function(user,idx){return _c('div',{key:idx},[_c('span',[_vm._v(_vm._s(user.name))])])})}},{key:"item.logo",fn:function(ref){
          var item = ref.item;
return [(item.logo)?_c('v-img',{attrs:{"max-width":"120","contain":"","src":_vm.showLogo(item.logo.buffer, item.logo.mimeType)}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('group', 'edit'))?_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.isAuthorized('group', 'attach'))?_c('v-icon',{attrs:{"title":"Liitä muokkaajia","small":""},on:{"click":function($event){return _vm.attachUsers(item)}}},[_vm._v("mdi-account-multiple")]):_vm._e(),(_vm.isAuthorized('group', 'delete'))?_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei ryhmiä")])]},proxy:true}],null,true)})],1),_c('group-form',{attrs:{"edit":_vm.editing},model:{value:(_vm.groupDialog),callback:function ($$v) {_vm.groupDialog=$$v},expression:"groupDialog"}}),_c('attach-users',{model:{value:(_vm.attachUsersDialog),callback:function ($$v) {_vm.attachUsersDialog=$$v},expression:"attachUsersDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
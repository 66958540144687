<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-container>
        <v-card-title> {{ title }} </v-card-title>

        <v-card-text class="mt-1">
          <v-form ref="form">
            <label>Nimi</label>
            <v-text-field
              :disabled="isNotSuperAdmin"
              v-model="currentGroup.name"
              outlined
              dense
              :rules="validations.required"
            ></v-text-field>

            <v-checkbox
              v-if="!isNotSuperAdmin"
              style="margin-top: 0px"
              v-model="currentGroup.bypassGroupStyles"
              label="Käytä Vuokranetin oletustyylejä"
            ></v-checkbox>

            <div v-if="currentGroup.bypassGroupStyles">
              Ryhmässä käytetään Vuokranetin oletustyylejä. Kysy ylläpidolta, mikäli haluat lisätä
              brändisi mukaisen ilmeen tileillesi.
            </div>

            <div v-show="!currentGroup.bypassGroupStyles">
              <label>Fontti</label>
              <v-select
                v-model="currentGroup.fonts.fontFamily"
                :items="globalValues.fonts"
                dense
                outlined
                :rules="validations.required"
                @change="updateFont"
              ></v-select>

              <label>Värit</label>
              <div v-for="(color, idx) in colors" :key="idx">
                <v-text-field
                  v-model="currentGroup.colors[color]"
                  class="ma-0 pa-0 mb-2"
                  :label="`${color[0].toUpperCase() + color.slice(1)}`"
                  outlined
                  dense
                  hide-details
                  readonly
                >
                  <template v-slot:append>
                    <v-menu
                      v-model="menus[`${idx}`]"
                      top
                      nudge-bottom="100"
                      nudge-left="16"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <div :style="getFieldStyle(color)" v-on="on" />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                            v-model="currentGroup.colors[color]"
                            flat
                            mode="hexa"
                            hide-mode-switch
                            @input="updateColors(color)"
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                </v-text-field>
              </div>

              <v-checkbox
                v-model="currentGroup.showFooter"
                label="Näytä alapalkki (footer)"
                hide-details
              ></v-checkbox>

              <p
                style="margin: 10px 0px 10px 0px"
                class="info--text"
                v-if="currentGroup.showFooter"
                v-html="'Voit käyttää tägiä {{logo}}, jolloin logo näytetään tekstien sijaan.'"
              ></p>

              <div v-if="currentGroup.showFooter">
                <label>Footer column 1</label>
                <v-textarea
                  v-model="currentGroup.texts.footer.column1"
                  outlined
                  dense
                  hide-details
                  @input="validateInput('column1')"
                ></v-textarea>

                <label>Footer column 2</label>
                <v-textarea
                  v-model="currentGroup.texts.footer.column2"
                  outlined
                  dense
                  hide-details
                  @input="validateInput('column2')"
                ></v-textarea>

                <label>Footer column 3</label>
                <v-textarea
                  v-model="currentGroup.texts.footer.column3"
                  outlined
                  dense
                  hide-details
                  @input="validateInput('column3')"
                ></v-textarea>
              </div>

              <label>Logo</label>
              <v-file-input
                v-model="logo"
                accept="image/png, image/jpeg, image/jpg"
                :rules="[validateFileSize]"
                outlined
                dense
              ></v-file-input>

              <v-img max-height="100" contain :src="imageUrl" v-if="imageUrl" />
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="currentGroup.bypassGroupStyles && isNotSuperAdmin"
            color="info"
            @click="createOrEdit"
            >{{ btnText }}</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins/mixins";
import { mapState, mapActions, mapMutations } from "vuex";
import validations from "@/validations";
import globalValues from "../../../configs/globalValues";
import { loadFont } from "@/utils/helpers";

export default {
  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      imageUrl: null,
      validations,
      globalValues,
      colors: ["primary", "info", "success", "warning", "error", "draft", "footer", "footer-text"],
      menus: {},
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("group", ["currentGroup"]),

    isNotSuperAdmin() {
      if (this.currentUser.role !== "superAdmin") return true;
      else return false;
    },

    title() {
      if (this.edit) return "Muokkaa ryhmää";
      else return "Luo ryhmä";
    },

    btnText() {
      if (this.edit) return "Tallenna";
      else return "Luo";
    },

    logo: {
      get() {
        return this.$store.state.group.currentGroup.logo;
      },
      set(logo) {
        if (logo) this.setLogo(logo);
        else this.setLogo(null);
      },
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    logo(logo) {
      if (logo) {
        this.imageUrl = URL.createObjectURL(logo);
      } else {
        this.imageUrl = null;
      }
    },

    value(val) {
      if (!val) {
        this.setDefaultGroup();
        URL.revokeObjectURL(this.imageUrl);
      } else if (!this.edit && val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
  },

  methods: {
    ...mapActions("group", ["createGroup", "editGroup"]),
    ...mapMutations("group", ["setLogo", "setDefaultGroup"]),

    async createOrEdit() {
      try {
        if (this.$refs.form.validate()) {
          if (this.edit) {
            await this.editGroup();
            this.showPopup("Ryhmää muokattu", "success");
            this.dialog = false;
          } else {
            await this.createGroup();
            this.showPopup("Ryhmä luotu", "success");
            this.dialog = false;
          }
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    validateFileSize(file) {
      if (!file) {
        return true;
      } else {
        const maxSizeKB = 100;
        if (file && file.size / 1024 <= maxSizeKB) {
          return true;
        }
        return `Logo ei saa olla suurempi kuin ${maxSizeKB}kt.`;
      }
    },

    getFieldStyle(color) {
      return {
        backgroundColor: this.currentGroup.colors[color],
        cursor: "pointer",
        height: "24px",
        width: "35px",
        borderRadius: this.currentGroup.colors[color] ? "4px" : "50%",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    updateFont(font) {
      if (
        this.currentGroup?._id &&
        this.currentUser?.currentAccount?.group?._id &&
        String(this.currentUser?.currentAccount?.group?._id) === String(this.currentGroup?._id)
      ) {
        // Set font
        loadFont(font);
        document.body.style.setProperty("--custom-body-font", font);
      }
    },

    updateColors(color) {
      if (
        this.currentGroup?._id &&
        this.currentUser?.currentAccount?.group?._id &&
        String(this.currentUser?.currentAccount?.group?._id) === String(this.currentGroup?._id)
      ) {
        const theme = this.$vuetify.theme.themes.light;
        theme[color] = this.currentGroup.colors[color];
      }
    },

    validateInput(column) {
      const allowedTags = ["div", "p", "span", "h2", "h3", "h4", "br"];
      const userInput = this.currentGroup.texts.footer[column];

      // Remove any script tags
      const sanitizedInput = userInput.replace(
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
        ""
      );

      // Allow only specific tags
      const cleanedInput = sanitizedInput.replace(
        /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
        (tag, match) => {
          return allowedTags.includes(match.toLowerCase()) ? tag : "";
        }
      );

      // Update the model with the cleaned input
      this.currentGroup.texts.footer[column] = cleanedInput;
    },
  },
};
</script>

<style scoped></style>

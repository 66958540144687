<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">Ryhmät</div>

    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isAuthorized('group', 'create')"
          color="info"
          class="mr-1 mb-2 ml-auto"
          @click="
            editing = false;
            groupDialog = true;
          "
        >
          Uusi ryhmä
        </v-btn>
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              label="Nimi"
              single-line
              hide-details
              append-icon="mdi-magnify"
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :headers="headersByRole"
        :items="groups"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- name  -->
        <template #[`item.name`]="{ item }">
          <span class="font-weight-bold">{{ item.name }}</span>
        </template>

        <!-- users  -->
        <template #[`item.users`]="{ item }">
          <div v-for="(user, idx) of item.users" :key="idx">
            <span>{{ user.name }}</span>
          </div>
        </template>

        <!-- logo  -->
        <template #[`item.logo`]="{ item }">
          <v-img
            v-if="item.logo"
            max-width="120"
            contain
            :src="showLogo(item.logo.buffer, item.logo.mimeType)"
          ></v-img>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('group', 'edit')"
              title="Muokkaa"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="isAuthorized('group', 'attach')"
              title="Liitä muokkaajia"
              small
              @click="attachUsers(item)"
              >mdi-account-multiple</v-icon
            >
            <v-icon
              v-if="isAuthorized('group', 'delete')"
              title="Poista"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
        <template #no-data>
          <h2>Ei ryhmiä</h2>
        </template>
      </v-data-table>
    </v-card>

    <!-- Groupform -->
    <group-form v-model="groupDialog" :edit="editing"></group-form>
    <attach-users v-model="attachUsersDialog"></attach-users>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
import GroupForm from "@/components/GroupComponents/Group/GroupForm";
import AttachUsers from "@/components/GroupComponents/Group/AttachUsers";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  title: "Ryhmät",
  mixins: [mixins],

  components: {
    GroupForm,
    AttachUsers,
  },

  data() {
    return {
      headers: [
        { text: "Nimi", value: "name", show: [1, 2] },
        { text: "Logo", value: "logo", show: [1, 2] },
        { text: "Muokkaajat", value: "users", show: [1] },
        { text: "Toiminnot", value: "actions", sortable: false, show: [1, 2] },
      ],
      search: "",
      editing: false,
      groupDialog: false,
      attachUsersDialog: false,
      loading: true,
      options: {},
      globalValues,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("group", ["groups", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    headersByRole() {
      if (this.currentUser.role === "superAdmin") {
        return this.headers;
      } else {
        return this.headers.filter((el) => el.show.includes(2));
      }
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("group", ["getGroups", "deleteGroup"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("group", ["setCurrentGroup"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();

      let url = `/api/v1/group?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      await this.getGroups(url);

      this.loading = false;
    },

    editItem(item) {
      this.editing = true;
      let data = { ...item };

      if (item.logo) {
        const mimeType = item.logo.mimeType;
        const fileName = item.logo.name;
        const fileBuffer = new Uint8Array(item.logo.buffer.data);
        data = { ...data, logo: new File([fileBuffer], fileName, { type: mimeType }) };
      }
      this.setCurrentGroup(data);
      this.groupDialog = true;
    },

    attachUsers(item) {
      let data = { ...item };
      data.logo = null;
      this.setCurrentGroup(data);
      this.attachUsersDialog = true;
    },

    async deleteItem(item) {
      try {
        if (
          confirm(
            `Oletko varma, että haluat poistaa ryhmän ${item.name}? Kaikki ryhmän asetukset poistetaan ryhmään liitetyiltä tileiltä.`
          )
        ) {
          await this.deleteGroup(item._id);
          this.showPopup("Ryhmä poistettu", "success");
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    showLogo(buffer, mimeType) {
      const blob = new Blob([new Uint8Array(buffer.data)], { type: mimeType });
      return URL.createObjectURL(blob);
    },
  },
};
</script>

<style scoped></style>

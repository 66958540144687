<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-container>
        <v-card-title> {{ currentGroup.name }} käyttäjät </v-card-title>

        <v-card-text class="mt-1">
          <v-form ref="form">
            <v-autocomplete
              v-model="users"
              :items="activeUsers"
              :item-text="nameAndEmail"
              item-value="_id"
              label="Käyttäjät"
              no-data-text="Käyttäjän nimi tai email"
              outlined
              multiple
              small-chips
              return-object
              deletable-chips
              no-filter
              hide-details
              @input.native="getUsers"
              :search-input.sync="searchInput"
              @change="searchInput = ''"
            >
            </v-autocomplete>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="edit">Tallenna</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins/mixins";
import { debounce } from "../../../utils/helpers";
import { mapState, mapActions, mapMutations } from "vuex";
import validations from "@/validations";
import globalValues from "../../../configs/globalValues";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      users: [],
      searchInput: "",
      userInput: "",
      validations,
      globalValues,
    };
  },

  computed: {
    ...mapState("group", ["currentGroup"]),
    ...mapState("userModule", ["activeUsers"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    currentGroup(val) {
      if (val && val.users && val.users.length > 0) {
        this.setToActiveUsers(val.users);
        this.users = val.users;
      } else {
        this.setToActiveUsers(null);
        this.users = [];
      }
    },

    userInput: debounce(function (newVal) {
      this.searchUsers(`/api/v1/user/search?search=${newVal}`);
    }, 1000),

    value(val) {
      if (!val) this.users = [];
    },
  },

  methods: {
    ...mapActions("userModule", ["searchUsers"]),
    ...mapMutations("userModule", ["setToActiveUsers"]),
    ...mapActions("group", ["attachUsersToGroup"]),

    getUsers(event) {
      this.userInput = event.target.value;
    },

    nameAndEmail(val) {
      return `${val.name} (${val.email})`;
    },

    async edit() {
      try {
        await this.attachUsersToGroup({ groupId: this.currentGroup._id, users: this.users });
        if (this.users.length > 0) {
          this.showPopup("Käyttäjät tallennettu ryhmään", "success");
        } else {
          this.showPopup("Käyttäjät poistettu ryhmästä", "success");
        }
        this.dialog = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
